<template>
  <v-container class="mt-5 ml-2" fluid >
    <v-row>
      <v-col cols="12">
        <v-card class="transparent elevation-0">
          <v-card-title primary-title class="text-subtitle-1">
            KPI Jefe Departamento
            <v-spacer></v-spacer>

            <v-btn 
            	color="primary"
            	@click="initialize4(), getNI()" 
            	small
            	tile
            >
          		Consultar
          	</v-btn>
          </v-card-title>


          <!-- Contenido de la pagina -->
          <v-card-text>

          	<!-- Filtros -->
          	<v-row>
              <v-col cols="12" sm="4" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  clearable
                  v-model="fechaini"
                  type="date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha final"
                  filled
                  dense
                  clearable
                  v-model="fechafin"
                  type="date"
                ></v-text-field>
              </v-col>
            </v-row>

          	<v-row>
          		<label>Departamento de TI</label>
          		<v-col cols="12">
		            <v-row>
		            	<v-col cols="12" md="6" lg="4">
		            		<v-card color="shadowCard text-center">
		            		  <v-card-title primary-title>
		            		  	<v-spacer></v-spacer>
		            		    <div> 
		            		    	<v-card :color="soporte.avance >= 95 ? 'green' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
		            		    		{{ soporte.avance }} %
		            		    	</v-card>
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  </v-card-title>

		            		  <v-card-text>
		            		    Valor aceptable: 95%
		            		  </v-card-text>
		            		  
		            		  <v-card-text class="black--text text-h6 pt-0 pb-1">
		            		    Soporte
		            		  </v-card-text>
		            		  <v-card-text class="black--text pt-0">
		            		  	TASA DE TICKETS RESUELTOS
		            		  </v-card-text>


		            		  <v-btn 
		            		  	color="blue"
		            		  	absolute
		            		  	top
		            		  	right
		            		  	icon
		            		  >
		            				<v-icon>mdi-information-outline</v-icon>
		            			</v-btn>
		            		</v-card>
		            	</v-col>

		            	<!-- <v-col cols="12" md="6" lg="4">
		            		<v-card color="shadowCard text-center">
		            		  <v-card-title primary-title>
		            		  	<v-spacer></v-spacer>
		            		    <div> 
		            		    	<v-card :color="soporte.rotacion <= 3 ? 'green' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
		            		    		{{ soporte.rotacion }} %
		            		    	</v-card>
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  </v-card-title>

		            		  <v-card-text>
		            		    Valor aceptable: 3%
		            		  </v-card-text>
		            		  
		            		  <v-card-text class="black--text text-h6 pt-0">
		            		    Recursos Humanos
		            		  </v-card-text>

		            		  <v-btn 
		            		  	color="blue"
		            		  	absolute
		            		  	top
		            		  	right
		            		  	icon
		            		  >
		            				<v-icon>mdi-information-outline</v-icon>
		            			</v-btn>
		            		</v-card>
		            	</v-col> -->
		            </v-row>
          		</v-col>
          	</v-row>

          	<v-divider class="my-6"></v-divider>

          	<v-row>
          		<label>Departamento de Comercial</label>
          		<v-col cols="12">
		            <v-row>
		            	<v-col cols="12" md="6" lg="4">
		            		<v-card color="shadowCard text-center">
		            		  <v-card-title primary-title>
		            		  	<v-spacer></v-spacer>
		            		    <div> 
		            		    	<v-card :color="community.contactos_INBI >= community.aceptable ? 'green' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
		            		    		{{ community.contactos_INBI }}
		            		    	</v-card>
		            		    	Inbi
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		    <div> 
		            		    	<v-card :color="community.contactos_FAST >= community.aceptable ? 'green' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
		            		    		{{ community.contactos_FAST }}
		            		    	</v-card>
		            		    	Fast
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  </v-card-title>

		            		  <v-card-text>
		            		    Valor aceptable: {{ community.aceptable }}
		            		  </v-card-text>
		            		  
		            		  <v-card-text class="black--text text-h6 pt-0 pb-1">
		            		    Community
		            		  </v-card-text>
		            		  <v-card-text class="black--text pt-0">
		            		  	#Contactos
		            		  </v-card-text>


		            		  <v-btn 
		            		  	color="blue"
		            		  	absolute
		            		  	top
		            		  	right
		            		  	icon
		            		  >
		            				<v-icon>mdi-information-outline</v-icon>
		            			</v-btn>
		            		</v-card>
		            	</v-col>

		            	<v-col cols="12" md="6" lg="4">
		            		<v-card color="shadowCard text-center">
		            		  <v-card-title primary-title>
		            		  	<v-spacer></v-spacer>
		            		    <div> 
		            		    	<v-card :color="community.avanceINBI >= 95 ? 'green' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
		            		    		{{ community.avanceINBI }} <span class="text-capitalize text-h6">%</span>
		            		    	</v-card>
		            		    	Inbi
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		    <div> 
		            		    	<v-card :color="community.avanceFAST >= 95 ? 'green' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
		            		    		{{ community.avanceFAST }} <span class="text-capitalize text-h6">%</span>
		            		    	</v-card>
		            		    	Fast
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  </v-card-title>

		            		  <v-card-text>
		            		    Valor aceptable: 95%
		            		  </v-card-text>
		            		  
		            		  <v-card-text class="black--text text-h6 pt-0 pb-1">
		            		    Community
		            		  </v-card-text>
		            		  <v-card-text class="black--text pt-0">
		            		  	% de conversión
		            		  </v-card-text>


		            		  <v-btn 
		            		  	color="blue"
		            		  	absolute
		            		  	top
		            		  	right
		            		  	icon
		            		  >
		            				<v-icon>mdi-information-outline</v-icon>
		            			</v-btn>
		            		</v-card>
		            	</v-col>
		            </v-row>
          		</v-col>
          	</v-row>

          	<v-divider class="my-6"></v-divider>

          	<v-row>
          		<label>Departamento de Finanzas</label>
          		<v-col cols="12">
		            <v-row>
		            	<v-col cols="12" md="6" lg="4">
		            		<v-card color="shadowCard text-center">
		            		  <v-card-title primary-title>
		            		  	<v-spacer></v-spacer>
		            		    <div> 
		            		    	<v-card :color="finanzas.avanceIngresos >= 100 ? 'green' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
		            		    		{{ finanzas.avanceIngresos }} %
		            		    	</v-card>
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  </v-card-title>

		            		  <v-card-text>
		            		    Valor aceptable: 100%
		            		  </v-card-text>
		            		  
		            		  <v-card-text class="black--text text-h6 pt-0 pb-1">
		            		    Analista de ingresos
		            		  </v-card-text>
		            		  <v-card-text class="black--text pt-0">
		            		  	% VALIDACIÓN DE INGRESOS
		            		  </v-card-text>


		            		  <v-btn 
		            		  	color="blue"
		            		  	absolute
		            		  	top
		            		  	right
		            		  	icon
		            		  >
		            				<v-icon>mdi-information-outline</v-icon>
		            			</v-btn>
		            		</v-card>
		            	</v-col>

		            	<v-col cols="12" md="6" lg="4">
		            		<v-card color="shadowCard text-center">
		            		  <v-card-title primary-title>
		            		  	<v-spacer></v-spacer>
		            		    <div> 
		            		    	<v-card :color="soporte.rotacion <= 3 ? 'green' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
		            		    		{{ soporte.rotacion }} %
		            		    	</v-card>
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  </v-card-title>

		            		  <v-card-text>
		            		    Valor aceptable: 100%
		            		  </v-card-text>
		            		  
		            		  <v-card-text class="black--text text-h6 pt-0 pb-1">
		            		    Analista de egresos
		            		  </v-card-text>
		            		  <v-card-text class="black--text pt-0">
		            		  	% VALIDACIÓN DE EGRESOS
		            		  </v-card-text>


		            		  <v-btn 
		            		  	color="blue"
		            		  	absolute
		            		  	top
		            		  	right
		            		  	icon
		            		  >
		            				<v-icon>mdi-information-outline</v-icon>
		            			</v-btn>
		            		</v-card>
		            	</v-col>
		            </v-row>
          		</v-col>
          	</v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
	import axios from 'axios';

  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  import XLSX from 'xlsx'

  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,


			ciclos:[],
      cicloInf:null,
      cicloSup:null,

      dialogAlumnos:false,

      headers: [
        { text: 'Vendedora'    , value: 'vendedora'  },
        { text: 'I completo'   , value: 'completos'  },
      ],

      desserts: [],

      fechaini:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fechafin:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      totalInbi:0,
      totalFast:0,

      headers2: [
        { text: 'Vendedora'       , value: 'nombre_completo'       },
        { text: 'Mat. Nvas. Liq.' , value: 'matriculas_liquidadas' },
      ],

      ciclo: null,

      soporte: { },
      totalFast: 0,
			totalInbi: 0,
			finanzas:{},
			totalFastM: 0,
			totalInbiM: 0,
			community:{},


    }),

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    watch: {
      cicloInf () {
        if(this.cicloInf){
          var num_search = parseInt(this.cicloInf.ciclo.substr(5,3)) + 1
          for(const i in this.ciclos){
            var cadena_search = this.ciclos[i].ciclo.substr(0,8)
            if (cadena_search.search(num_search) != -1) {
              this.cicloSup = this.ciclos[i]
            }
          }
        }
      }
    },

    async created () {
      await this.getCiclos()
      await this.initialize4()
    },

    methods: {

    	getCiclos () {
        this.cargar    = true
        this.ciclos    = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    	async initialize4(){
    		await this.initialize()
    	},

    	initialize () {
        this.cargar    = true
        var payload = { 
        	fechaini: this.cicloInf ? this.cicloInf.fecha_inicio_ciclo : this.fechaini, 
        	fechafin: this.cicloInf ? this.cicloInf.fecha_fin_ciclo : this.fechafin, 
        }

        this.$http.post('kpi.departamento.auxiliar', payload ).then(response=>{

          this.soporte   = response.data.soporte 
          this.community = response.data.community 
          this.finanzas  = response.data.finanzas 

          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      getNI(){
      	var payload = { 
        	fechaini: this.cicloInf ? this.cicloInf.fecha_inicio_ciclo : this.fechaini, 
        	fechafin: this.cicloInf ? this.cicloInf.fecha_fin_ciclo : this.fechafin, 
        }

        this.$http.post('nuevas.matriculas',payload).then(response=>{
        	console.log( response.data.merca)
          // Guardar los datos 
					this.totalFast        = response.data.totalFast 
					this.totalInbi        = response.data.totalInbi
					this.totalFastM       = response.data.merca.find( el => el.escuela == 2 )
					this.totalInbiM       = response.data.merca.find( el => el.escuela == 1 )

          this.cargar = false
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
      },


      abrirDialog( value ){
        this.alumnos = value
        this.dialogAlumnos = true
      },

      exportar(){
        this.exportarData()
      },

      s2ab2(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

    },
  }
</script>